.sbl_wrap {
  margin-bottom: 112px;
  overflow: hidden;
}

.sblLink {
  font-size: 25px;
  vertical-align: middle;
  color: #888;
  margin-left: 5px;
}

.sbldsign_wrap {
  display: block;
  position: relative;
  width: 100vw;
  padding-bottom: 700px;
}

.sbldsign {
  margin-top: 3.34vw;
  width: 100%;
  padding-top: 30px;
  position: absolute;
  height: 80vh;
  display: flex;
  align-items: flex-start;
}

.sbl_image_wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  height: auto;
}

.sbldesign_img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .sbl_wrap {

  }

  .sbldesign_img {
    max-width: 340px;
  }
}

@media screen and (max-width: 768px) {
  .sbldesign_img {
    max-width: 300px;
  }
  
}

@media screen and (max-width: 479px) {
  .sbl_wrap {
    margin: 40px 0 112px;
  }

  .sbldsign_wrap {
    display: block;
    position: relative;
    width: 100vw;
  }

  .sbldsign {
    margin-top: 0;
    padding-top: 16px;
    height: auto;
    flex-direction: column;
  }

  .sbl_image_wrap {
    position: relative;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
  }

  .sbldesign_img {
    max-height: 754px;
    width: 100%;
    max-width: none;
  }

}