.sbl_image_wrap {
  position: relative;
  width: 45%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sbldesign_img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  max-height: 754px;
  height: 80vh;
}