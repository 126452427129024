.sbl_text {
  width: 100%;
  height: 21vw;
  overflow: hidden;
}

.sbl_title {
  display: block;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 2vw;
  color: #888;
  margin: 5px 0;
}

.sbl_title_overflow {
  height: 2vw;
  overflow: hidden;
}

.sbl_text_wrap {
  margin-left: 3.34vw;
  width: 45%;
  position: relative;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 500;
    font-size: 1.7vw;
    line-height: 2vw;

    a {
      display: block;
      
    }

    &:not(:last-child) {
      margin-bottom: 3.34vw;
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }
}


.progress {
  position: relative;
  height: 1px;
  background: #888;
  margin: 20px 0 ;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    background: #232323;
    height: 1px;
    width: 125px;
  }
}

@media screen and (max-width: 768px) {
  .progress {
    margin: 10px 0 16px;
  }
  
  .sbl_title_overflow {
    height: 17px;
  }

  .sbl_text {
    width: 80%;
    height: 28vw;
  }
}

@media screen and (max-width: 479px) {
  .sbl_text {
    width: 97%;
    height: auto;
  }

  .sbl_text_wrap {
    margin-left: 0;
    width: 100%;

    p {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .sbl_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .sbl_title_overflow {
    height: 29px;
  }
}