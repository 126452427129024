.description {
  display: block;
  width: 60vw;
  padding: 1.9vw 0 2.92vw;
  font-family: "Mazzard-Light";
  color: #929292;
}

.presentation {
  h1 {
    width: 77vw;
  }
}

.presentation_elevator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.3vw 0 12.5vw;
}

.elevator {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  &:not(:first-child) {
    margin-left: 1.6vw;
  }
}

.elevator_img {
  width: 100%;
  height: 100%;
}

.elevator_text {
  position: absolute;
  top: 0;
  right: -24px;
  font-size: 12px;
  line-height: 14px;
  background: transparent;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-top: 7px;
  white-space: nowrap;
  z-index: 9;
}

.btn_openModal {
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 1024px) {
  .description {
    width: 70vw;
  }

  .presentation_elevator {
    flex-wrap: wrap;
    padding-bottom: 5.5vw;
  }

  .elevator {
    width: 48%;
    margin-bottom: 3.2vw;

    &:nth-child(3) {
      margin-left: 0;
    }
  }

  .elevator_img {
    width: 100%;
    height: 66vw;
  }
}

@media screen and (max-width: 768px) {
  .presentation {
    padding-top: 4.6vw;
  }

  .elevator_text {
    margin-left: -4px;
  }
}

@media screen and (max-width: 479px) {
  .presentation {
    h1 {
      width: 100%;
    }
  }

  .description {
    font-size: 16px;
    line-height: 19px;
    padding: 16px 0 24px;
    width: 100%;
  }

  .presentation_elevator {
    padding: 40px 0 80px;
  }

  .elevator_text {
    display: none;
  }
  
  .elevator {
    width: 100%;
    
    &:not(:first-child) {
      margin-left: 0;
    }
  }

  .elevator_img {
    width: 100%;
    height: 120vw;
  }
}