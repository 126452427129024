.presentation {

}

.presentation_text {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.presentation_subtitle {
  font-size: 1.7vw;
  line-height: 2vw;
  width: 31vw;
}

.presentation_description {
  font-family: "Mazzard-Light";
  display: block;
  width: 32.8vw;
  margin: 0 13.1vw 4.2vw 0;
}


.presentation_video {
  padding: 6vw 0 12.5vw;
  will-change: transform;
}

.video_wrap {
  margin: 0 auto;
  height: 100vh;
  position: relative;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

 @keyframes spin {
   from {
     transform: rotate(0turn);
   }

   to {
     transform: rotate(1turn);
   }
 }

 .spinner_wrap {
  background: #ccc;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  
 }

 .spinner {
  animation: spin 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin: 0 auto;
  display: block;
  width: 32px;
  height: 32px;
 }

@media screen and (max-width: 1024px) {
  .presentation_subtitle {
    font-size: 24px;
    line-height: 28px;
    width: 34vw;
  }
  
  .presentation_description {
    width: 44vw;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding-bottom: 56px;
  }

  .video_wrap {
    height: 50vh;
  }
}

@media screen and (max-width: 768px) {
  .presentation_text {
    display: block;
    width: 67vw;
    padding-top: 32px;
  }

  .presentation_subtitle {
    padding-bottom: 16px;
    display: block;
    width: auto;
  }

  .presentation_description {
    padding-bottom: 48px;
    font-size: 16px;
    line-height: 19px;
    width: 56vw;
  }

  .video_wrap {
    height: 40vh;
  }
}

@media screen and (max-width: 479px) {
  .presentation_text {
    width: 100%;
  }

  .presentation_text {
    padding-top: 12px;
  }

  .presentation_subtitle {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 24px;
  }

  .presentation_description {
    width: 100%;
    padding-bottom: 32px;
  }
}