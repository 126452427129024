.back_drop {
  background: rgba(63, 61, 61, 0.692);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1.3s;
  width: 100%;
  z-index: 10;
}

.thx {
  margin: 0 auto;
  font-family: "Mazzard-SemiBold";
  color: #EFEFEF;
  font-weight: 600;
  font-size: 3.8vw;
  line-height: 4.6vw;
  width: 50vw;
  padding-bottom: 1.3vw;
  margin-bottom: 0;
}

.modal_title {
  font-weight: 600;
  font-size: 3.8vw;
  line-height: 4.5vw;
  color: #EFEFEF;
}

.form_wrap {
  position: relative;
  display: inline;

  .required {
    position: absolute;
    bottom: -38px;
    left: 0;
    color: #aa5959;
  }
}

.required {
  font-size: 11px;
}

.modal_wrapper {
  font-family: "Mazzard-SemiBold";
  background: #232323;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  transition: all .5s ease;
  z-index: 11;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 5vw 4.45vw;
}

.close_modal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  width: 48px;
  height: 48px;

  &:hover {
    &::before, &::after {
      background: #eee;
    }
  }

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #646464;
    border-radius: 3px;
    transition: 0.3s;
  }

  &:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  &:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
 
}

.modal_body {
  label {
    margin-right: 1.25vw;
    font-weight: 600;
    font-size: 1.9vw;
    line-height: 2.25vw;
    color: #efefef;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #646464;
    -webkit-text-fill-color: #efefef;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%);
  }
}

.top_input {
  padding-top: 3.75vw;
}

.input_name,
.input_company,
.input_contact,
.input_phone {
  font-family: "Mazzard-SemiBold", sans-serif;
  font-weight: 600;
  font-size: 1.9vw;
  line-height: 2.25vw;
  max-width: 20vw;
  width: 100%;
  color: #efefef;
  background: transparent;
  border: none;
  border-bottom: 1px solid #646464;
  padding: 0 1vw 8px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 11px;
  }

  &:focus {
    &::-webkit-input-placeholder {
      text-align: center;
      position: relative;
      z-index: 1;
      color: transparent;
    }
    &:-ms-input-placeholder {
      text-align: center;
      position: relative;
      z-index: 1;
      color: transparent;
    }
    &::-ms-input-placeholder {
      text-align: center;
      position: relative;
      z-index: 1;
      color: transparent;
    }
    &::placeholder {
      text-align: center;
      position: relative;
      z-index: 1;
      color: transparent;
    }
  }

  &::-webkit-input-placeholder {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #646464;
    font-size: 1.9vw;
    line-height: 2.25vw;
  }

  &:-ms-input-placeholder {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #646464;
    font-size: 1.9vw;
    line-height: 2.25vw;
  }

  &::-ms-input-placeholder {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #646464;
    font-size: 1.9vw;
    line-height: 2.25vw;
  }

  &::placeholder {
    text-align: center;
    position: relative;
    z-index: 1;
    color: #646464;
    font-size: 1.9vw;
    line-height: 2.25vw;

    div {
      position: relative;
    }
  }
}

.input_name {
  margin-right: 1.2vw;
}

.input_company {
  max-width: 23vw;
  margin-right: 0;
}

.input_contact {
  max-width: 31vw;
  margin-right: 16px;
  width: 100%;
}

.input_contact_wrap {
  color: #efefef;
  font-weight: 600;
  font-size: 1.9vw;
  line-height: 2.25vw;
  padding: 2.1vw 0 2.1vw;
  display: flex;
  
  // &::after {
  //   content: '!';
  //   font-size: 1.9vw;
  //   line-height: 2.25vw;
  // }
}

.input_phone_wrap { 
  padding-bottom: 2.92vw;
  position: relative;
}

.input_file_wrap {
  display: flex;
  align-items: center;
  padding-bottom: 3.56vw;
  max-width: 100%;

  label {
    line-height: 0;
  }
}

.input_file::-webkit-file-upload-button {
  visibility: hidden;
  width: 19vw;
}

.input_file::before {
  content: 'Прикрепить файл';
  font-family: "Mazzard-SemiBold", sans-serif;
  width: 100%;
  display: inline-block;
  background: transparent;
  font-weight: 600;
  font-size: 1.9vw;
  line-height: 2.25vw;
  color: #EDEDED;
  padding-left: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.modal_footer {
  
  button {
    border: none;
    cursor: pointer;
  }

  .privacy_policy {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #B9B9B9;
    margin-left: 16px;
  
    a {
      color: #B9B9B9;
      border-bottom: 1px solid #B9B9B9;
      margin-left: 4px;
      &:hover {
        color: #ededed;
        border-bottom: 1px solid #ededed;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .close_modal_btn {
    width: 38px;
    height: 38px;
  }

  .modal_footer {
    display: flex;
    flex-direction: column;
    
    button {
      width: 204px;
      margin-bottom: 16px;
    }

    .privacy_policy {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal_wrapper {
    width: 90%;
  }

  .input_company_wrap {
    margin: 16px 0;
  }

  .close_modal_btn {
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
  }

  .input_phone_wrap {
    display: flex;
  }

  .input_phone_wrap {
    width: 200px;
  }

  .modal_body {
    h4 {
      font-size: 20px;
      line-height: 24px;
    }

    label {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .top_input { 
    padding-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      margin-bottom: 16px;
    }
  }

  .input_name_wrap {
    width: 100%;
    margin-right: 0;
  }

  .input_company_wrap {
    width: 100%;

    label {
      padding-bottom: 4px;
    }
  }

  .input_contact_wrap {
    padding: 0;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;

    label {
      display: block;
      margin-bottom: 4px;
    }

    &::after {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .input_name,
  .input_company,
  .input_contact,
  .input_phone {
    font-size: 16px;
    line-height: 19px;
    padding: 0 8px;
    max-width: 100%;

    &::-webkit-input-placeholder {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }

    &:-ms-input-placeholder {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }

    &::-ms-input-placeholder {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }

    &::placeholder {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .input_name {
    width: 142px;
  }

  .input_company {
    width: 176px;
  }

  .input_contact {
    width: 236px;
    margin: 0;
  }

  .input_file::before {
    font-size: 16px;
    line-height: 19px;
  }

  .input_file_wrap {
    padding-bottom: 32px;
  }

  .modal_footer {
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@media screen and (max-width: 479px) {
  .modal_wrapper {
    padding-top: 50px;
  }

  .close_modal_btn {
    top: 10px;
    right: 10px;
  }

  .modal_footer {
    .privacy_policy {
      a {
        margin-left: 0;
        display: inline-block;
      }
    }
  }
}