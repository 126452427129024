@import url(./fonts.scss);
@import url(./buttons.scss);

h1, h2, h3, h4, h5 {
  font-family: "Mazzard-SemiBold";
}

input[type="text"] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  text-decoration: none;
}

button { 
  border: none;
}

.title {
  font-size: 4.6vw;
  line-height: 4.8vw;
  font-weight: 600;
}

.title_white, .title_black {
  display: block;
  font-size: 72px;
  line-height: 85px;
  font-weight: 600;
}

.title_white {
  color: #EEEEEE;
}

.subtitle {
  font-weight: 600;
  font-size: 3.75vw;
  line-height: 4.9vw;
}

.description {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}

.description_white {
  color: #EEEEEE;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.elevator_class_title {
  font-weight: 500;
  font-size: 2.7vw;
  line-height: 3.2vw;
  color: #EEEEEE;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #EBEBEB !important;
}

#clientsSwiper .swiper-button-next,
#clientsSwiper .swiper-button-prev {
  color: #000 !important;
}

#clientsSwiper {}

@media screen and (max-width: 1250px) {
  .title {
    font-size: 5.2vw;
  }

  .description {
    font-size: 20px;
  }
}


@media screen and (max-width: 1024px) {
  .title_black, .title_white {
    font-size: 56px;
    line-height: 66px;
  }

  .elevator_class_title {
    font-size: 48px;
    line-height: 57px;
  }
}

@media screen and (max-width: 768px) {
  .description {
    font-size: 18px;
    line-height: 22px;
  }

  #clientsSwiper .swiper-button-next,
  #clientsSwiper .swiper-button-prev {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .title {
    font-size: 32px;
    line-height: 32px;
  }

  .title_black, .title_white {
    font-size: 40px;
    line-height: 47px;
  }

  .description_white {
    font-size: 32px;
    line-height: 38px;
  }
  
  .elevator_class_title {
    font-size: 32px;
    line-height: 38px;
  }
}