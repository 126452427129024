.elevator_classes_wrap {
  background: #2A2A2A;
  padding: 6.3vw 0 5.45vw;
}

.elevator_classes_text {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6vw;

  span {
    font-family: "Mazzard-Light";
    width: 33vw;
  }
}

.elevator_classes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7vw 0;

  &:not(:last-child) {
    border-bottom: 1px solid #737373;
  }
}

.class {
  h3 {
    font-family: 'Mazzard-Medium';
  }
}

@media screen and (max-width: 1024px) {
  .elevator_classes_wrap {
    padding-bottom: 2.25vw;
  }

  .elevator_classes_text {
    span {
      font-size: 16px;
      line-height: 19px;
      width: 38vw;
    }
  }

  .elevator_classes {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .elevator_classes_wrap {
   
  }

  .class {
    width: 74%;
  }

  .elevator_classes_text {
    display: block;
    padding-bottom: 16px;

    span {
      display: block;
      padding-top: 16px;
      width: 50vw;
    }
  }

  .elevator_classes {
    padding: 32px 0;

    span {
      font-size: 16px;
      line-height: 19px;
    }

    &:not(:last-child) {

    }
  }
}

@media screen and (max-width: 479px) {
  .elevator_classes_wrap {
    padding: 40px 0 32px;
  }

  .class {
    width: 50%;
  }

  .elevator_classes_text {

    span {
      width: 100%;
    }
  }

  .elevator_classes {
    align-items: flex-start;
    padding: 24px 0;
  }
}