
.elevator_info {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #232323;
  padding: 48px 0 120px;
}

.elevator_page {
  font-family: "Mazzard-Medium";
  color: #888;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 2vw;
}

.elevator_info_text {
  width: 73%;
}

.elevator_title {
  font-family: 'Mazzard-SemiBold';
  font-weight: 600;
  font-size: 3.34vw;
  line-height: 3.65vw;
}

.elevator_wrap {
  padding-bottom: 2.1vw;
}

.elevator_name {
  font-family: 'Mazzard-Medium';
  font-weight: 500;
  display: block;
  font-weight: 600;
  padding-bottom: 8px;
}

.elevator_page, .elevator_name {
  font-size: 1.7vw;
  line-height: 2vw;
}

.elevator {
  display: flex;
  border-top: 1px solid #232323;
  padding: 2.5vw 0 3.8vw;
}

.elevator_img {
  width: 31%;
}

.elevator_text_wrap {
  margin-left: 6.3vw;
  width: 85%;
  
  button {
    margin-top: 1.7vw;
  }
}

.elevator_text {
  margin-bottom: 1.7vw;
}

.type {
  display: block;
  font-family: "Mazzard-Medium";
  font-size: 1.3vw;
  line-height: 1.5vw;
  color: #888;
  padding-bottom: 8px;
}

.type_info {
  display: block;
  font-size: 1.7vw;
  line-height: 2vw;
}

.back_drop {
  background: rgba(63, 61, 61, 0.692);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1.3s;
  width: 100%;
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .elevator_info {
    padding: 40px 0 104px;
  }

  .elevator_title {
    font-size: 3.2vw;
    line-height: 4.6vw;
  }

  .elevator_page {
    font-size: 2.4vw;
    line-height: 2.8vw;
  }

  .elevator_wrap {
    padding-bottom: 48px;
  }

  .elevator {
    padding: 24px 0 64px;
  }

  .elevator_text_wrap {
    margin-left: 2vw;
  }

  .type {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 4px;
  }

  .type_info {
    font-size: 20px;
    line-height: 24px;
  }

  .elevator_text {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 768px) {
  .elevator_info {
    padding: 24px 0 88px;
  }

  .elevator_page,
  .elevator_name {
    font-size: 20px;
    line-height: 24px;
  }

  .elevator_title {
    font-size: 24px;
    line-height: 39px;
  }

  .elevator {
    display: block;
    padding: 24px 0 72px;
  }

  .elevator_img {
    width: 100%;
    max-height: 750px;
  }

  .elevator_text_wrap {
    margin-top: 32px;
    margin-left: 0;
    width: 100%;
    button {
      margin-top: 16px;
    }
  }

  .type {
    font-size: 16px;
    line-height: 19px;
  }

  .type_info {
    font-size: 24px;
    line-height: 28px;
  }

  .elevator_wrap {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 479px) {
  .elevator_info {
    padding: 40px 0 72px;
    flex-direction: column;
  }

  .elevator_info_text {
    width: 100%;
  }

  .elevator_title {
    font-size: 20px;
    line-height: 28px;
  }

  .elevator_wrap {
    padding-bottom: 88px;
  }

  .elevator_page,
  .elevator_name {
    font-size: 16px;
    line-height: 19px;
  }

  .elevator {
    padding: 16px 0 24px;
  }

  .elevator_text_wrap {
    margin-top: 24px;
  }

  .type {
    font-size: 14px;
    line-height: 17px;
  }

  .type_info {
    font-size: 16px;
    line-height: 19px;
  }
}