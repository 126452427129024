.sbldesign {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12.5vw 0;
}

.sbldesign_text {
  width: 50%; 
  
  p {
    font-family: 'Mazzard-Medium', sans-serif;
    padding-bottom: 3.4vw;
    font-weight: 500;
    font-size: 2.1vw;
    line-height: 2.45vw;
  }

  a {
    margin-top: 1.12vw;
  }
}


@media screen and (max-width: 768px) {
  .sbldesign {
    flex-direction: column;
  }

  .sbldesign_text {
    display: flex;
    flex-direction: column;
    padding: 32px 0 8px;
    align-items: flex-start;
    width: 100%;

    p {
      width: 70%;
    }
  }
}

@media screen and (max-width: 479px) {
  .sbldesign {
    padding: 80px 0;
  }

  .sbldesign_text {

    p {
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 24px;
    }
  }
}