
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 93vw;
  padding-top: 2vw;
  margin: 0 auto;
  margin-bottom: 5.9vw;
}

.nav_link {
  color: #2a2a2a;
  position: relative;
  padding-bottom: 5px;

  &:not(:first-child) {
    margin-left: 40px;
  }
  
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
}