.breadCrumbs {
  padding: 4.6vw 0 1.3vw;
  a {
    color: #888;
  }
}

.location {
  &::before {
    content: '/';
    color: #888;
    margin: 0 12px;
  }  
}

@media screen and (max-width: 768px) {
  .breadCrumbs {
    padding: 56px 0 8px;
    a {
      font-size: 16px;
      line-height: 19px;
    }
  }
  
  .location {
    font-size: 16px;
    line-height: 19px;
    &::before {
      margin: 0 8px;
    }
  }
}

@media screen and (max-width: 479px) {
  .breadCrumbs {
    padding: 40px 0 8px;

    a {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .location {
    font-size: 14px;
      line-height: 17px;

    &::before {
      margin: 0 8px;
    }
  }
}