.footer {
  background: #232323;
  color: #EEEEEE;
}

.footer_top {
  display: flex;
  justify-content: space-between;
  padding: 1.7vw 0;
  border-bottom: 1px solid #737373;
}

.footer_nav {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.footer_link {
  color: #EEEEEE;
  position: relative;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 1.25vw;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #EEEEEE;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  
  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
}

.footer_contacts {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.footer_contact {
  color: #EEEEEE;
  position: relative;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 24px;
  margin-left: 2.2vw;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #EEEEEE;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: bottom right;
              transform-origin: bottom right;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
    }
  
    &:hover {
      &::after {
        -webkit-transform-origin: bottom left;
                transform-origin: bottom left;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
      }
    }
  }
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  padding: 1.7vw 0;
  
}

.social_link {
  &:hover {
    svg path {
      fill: #797979;
    }
  }
  &:not(:first-child) {
    margin-left: 1.25vw;
  }
}

@media screen and (max-width: 1665px) {
  .footer_top {
    padding: 32px 0 16px;
  }

  .footer_nav {
    display: flex;
    flex-wrap: wrap;
    width: 27vw;
  }

  .footer_link {
    margin-bottom: 16px;
    margin-right: 0;

    &:not(:nth-child(even)) {
      width: 78px;
      margin-right: 5vw;
    }
  }


  .footer_contacts {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 60%;
    margin-left: 0;

    &:not(:first-child) {
      margin-left: 0;
    }
  }

  .footer_contact {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;

    &:last-child {
      margin-left: 0;
    }
  }

  .footer_bottom {
    padding: 24px 0;
  }

}

@media screen and (max-width: 1550px) {
  .footer_top {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .footer_top {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .footer_nav {
    width: 31vw;
  }

  .footer_link {
    &:nth-child(even) {
      margin-right: 0;
    }
  }

  .footer_contact:last-child {
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .footer_top {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .footer_nav {
    width: 93.4vw;
    border-bottom: 1px solid #737373;
  }

  .footer_link {
    width: 40vw;
    margin-bottom: 24px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 19px;

    &:not(:nth-child(even)) {
      width: 40vw;
      margin-right: 5vw;
    }

    &::after {
      content: none;
    }
  }

  .footer_contacts {
    padding-top: 16px;
    align-items: flex-start;
    width: 100%;
  }

  .footer_contact {
    font-size: 16px;
    line-height: 19px;
    margin-left: 0;
    padding-bottom: 0;

    &:last-child {
      white-space: normal;
    }

    &:not(:last-child) {
      &::after {
        content: none;
      }
    }

    .social_link {
      &:not(:first-child) {
        margin-left: 3vw;
      }
    }
  }

  .footer_bottom {
    padding: 16px 0;

    span {
      display: block;
      width: 60vw;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .social_link {
    &:not(:first-child) {
      padding-left: 16px;
    }
  }
}