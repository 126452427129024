.back_drop {
  background: rgba(63, 61, 61, 0.692);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1.3s;
  width: 100%;
  z-index: 10;
}

.modal {
  font-family: "Mazzard-SemiBold";
  background: #232323;
  width: 80%;
  height: max-content;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  transition: all .5s ease;
  z-index: 11;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 5vw 4.45vw 0 4.45vw;
}

.modal_description {
  font-size: 20px;
  line-height: 24px;
  color: #888888;
  margin: 0.8vw 0 5vw;
}

.close_modal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  width: 48px;
  height: 48px;

  &:hover {

    &::before,
    &::after {
      background: #eee;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #646464;
    border-radius: 3px;
    transition: 0.3s;
  }

  &:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

}

.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper {
  text-align: center;
  margin-bottom: 4.45vw;

  img {
    max-width: 80%;
    max-height: 350px;
    object-fit: cover;
  }
}


@media screen and (max-width: 768px) {
  .modal_description {
    font-size: 16px;
    line-height: 19px;
  }

  .close_modal_btn {
    top: 8px;
    right: 8px;
  }

  .swiper_slide {
    max-width: 60%;
  }
}