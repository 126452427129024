.clients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5.7vw;
  flex-wrap: nowrap;
  overflow: scroll;
}

.client {
  display: flex;
  flex-direction: column;
  padding-bottom: 3.4vw;
  width: 25%;  
  margin-right: 144px;
}

.client_description {
  font-family: "Mazzard-Light";
  display: block;
  margin: 1.7vw 0;
  color: #515151;
  font-size: 1.1vw;
  line-height: 1.1vw;
  width: 220px;
}

.client_logo {
  display: flex;
  align-items: center;
  height: 82px;
}

.client_btn {
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-bottom: 5px;
  font-family: 'Mazzard-Medium';
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #2A2A2A;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

@media screen and (max-width: 1024px) {
  .client {
    flex-wrap: wrap;
    width: 33vw;
    padding-bottom: 56px;
  }


  .client_description {
    font-size: 18px;
    line-height: 21px;
  }

  .client_logo {
    
  }

  .client_btn {
    
  }
}

@media screen and (max-width: 768px) {
  .clients_wrap {

  }

  .clients {
    
  }

  .client {
    width: 40.2vw;

    &:not(:first-child, :nth-child(5n+5)) {

    }

    &:not(:first-child, :nth-child(odd)) {
      margin-left: 60px;
    }
  }

  .client_description {
    font-size: 16px;
    line-height: 19px;
  }

  .client_logo {
    
  }

  .client_btn {
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (max-width: 479px) {
  .clients {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .client {
    width: 80vw;
    
    &:not(:first-child, :nth-child(odd)) {
      margin-left: 0;
    }

    &:not(:first-child) {
      margin-left: 28px;
    }
  }

  .client_btn {
    padding-bottom: 0;
    
    &::after {
      content: none;
    }
  }

  .client_description {
    width: 270px;
    padding: 24px 0 16px;
  }
}