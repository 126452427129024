.button__text {
  font-size: 20px;
  line-height: 24px;
}

.button_white {
  color: #2A2A2A;
  background: #EEEEEE;
}

.button_black, .button_white {
  display: inline-block;
  text-align: center;
  position: relative;
  color: #EEEEEE;
  background: #2A2A2A;
  border-radius: 40px;
  transition: .3s;
  -webkit-transition: .3s;
  overflow: hidden;
  cursor: pointer;
  z-index: 0;

  .button_circle_holder {
    position: absolute;
    width: 15px;
    height: 15px;
    display: block;
    top: 32%;
    left: 9px;
  }

  &:hover {
    background-color: #929292;
    
    .button_circle {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(-1, -1);
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform-origin: center;
      -webkit-transform-origin: center;
      background-color: #2A2A2A;
      border-radius: 50%;
      -webkit-border-radius: 50% !important; 
      transition: width .4s cubic-bezier(.215, .61, .355, 1),
        height .4s cubic-bezier(.215, .61, .355, 1),
        color .4s cubic-bezier(.215, .61, .355, 1);
      -webkit-transition: width .4s cubic-bezier(.215, .61, .355, 1),
          height .4s cubic-bezier(.215, .61, .355, 1),
          color .4s cubic-bezier(.215, .61, .355, 1);     
          z-index: -1;
    }

    .button__text {
      color: #EEEEEE;
      transition: .5s;
      -webkit-transition: .5s;
      margin-left: 14px;
    }
  }

  .button_circle {
    width: 150px;
    height: 150px;
    border-radius: 40px;
    -webkit-border-radius: 40px;
  }

  .button__text {
    color: #F0F0F0;
    transition: .5s;
    -webkit-transition: .5s;
  }

  .button__text {
    display: block;
    font-size: 15px;
    font-family: Mazzard;
    color: #F0F0F0;
    padding: 9px 16px 8px;
    position: relative;
  }
}

.button_white {
  color: #2A2A2A;
  background: #F0F0F0;
  overflow: hidden;

  &:hover {
    background-color: #929292;

    .button_circle {
      background-color: #F0F0F0;
    }

    .button__text {
      color: #F0F0F0;
    }
  }

  .button__text {
    color: #2A2A2A;
  }

  .button__text {
    color: #2A2A2A;
  }
}

@media screen and (max-width: 1024px) {
  .button_black,
  .button_white {
    .button__text {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@media screen and (max-width: 768px) {
  .button_black, .button_white {
    &:hover {
      background: #2A2A2A;
      .button_circle {
        position: static;
        transform: none;
        transform-origin: none;
        transition: none;
      }

      .button__text {
        margin-left: 0;
      }
    }
  }

  .button_white {
    &:hover {
      background: #EEEEEE;
      .button__text {
        color: #2A2A2A;
      }
    }
  }
}