@font-face {
  font-family: Mazzard;
  src: url("../assets/fonts/MazzardH-Regular.ttf");
}

@font-face {
  font-family: "Mazzard-Medium";
  src: url("../assets/fonts/MazzardH-Medium.ttf");
}

@font-face {
  font-family: "Mazzard-Light";
  src: url("../assets/fonts/MazzardH-Light.ttf");
}
@font-face {
  font-family: 'Mazzard-SemiBold';
  src: url("../assets/fonts/MazzardH-SemiBold.ttf");
}

