.contact_block {
  padding-top: 12.5vw;

  h2 {
    margin-bottom: 32px;
  }
}

.back_drop {
  background: rgba(63, 61, 61, 0.692);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1.3s;
  width: 100%;
  z-index: 10;
}

.btn_openModal {
  cursor: pointer;
  border: none;
}

.contacts_wrap {
  display: flex;
  justify-content: space-between;
  height: 374px;
  align-items: center;
  margin-top: 1.7vw;
  margin-bottom: 112px;
}

.contacts {
  width: 50%;
  padding: 6.3vw 0 4.7vw;
  display: flex;
  flex-wrap: wrap;

}

.contact {
  width: 24%;
  padding-bottom: 3.9vw;

  &:not(:last-child) {
    margin-right: 4.2vw;
  }

  &:nth-child(3) {
    margin-right: 0;
  }

  &:nth-child(1), &:nth-child(4) {
    width: 33%;
  }
}

.contact_title, .contact_description {
  display: block;
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 300;
  color: #727272;
  padding-bottom: 0.5vw;
}

.contact_title {
  font-family: "Mazzard-Light";
}

.contact_description {
  color: #2A2A2A;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #000;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
}

.contact_description_address {
  cursor: auto;
  &::after {
    content: none;
  }
}

.contacts_text {
  width: 50%;
  position: relative;
  padding: 6.3vw 0 4.2vw 4.6vw;
  
  p {
    font-family: 'Mazzard-Medium';
    font-weight: 500;
    font-size: 2.1vw;
    line-height: 2.45vw;
    padding-bottom: 2.5vw;
    color: #F0F0F0;

    &:nth-child(2) {
      font-size: 1.5vw;
      line-height: 1.8vw;
    }
  }
  

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -47.1px;
    background: #232323;
    width: 50vw;
    height: 100%;
    z-index: -1;
  }
}

@media screen and (max-width: 1024px) {
  .contacts_wrap {
    display: block;
    height: auto;
  }

  .contacts {
    width: 100%;

  }

  .contact {
    width: 25%;

    &:not(:last-child) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      width: 33%;
    }

    &:not(:nth-child(2)) {
      margin-right: 11.6vw;
    }

    &:nth-child(2) a {
      white-space: nowrap;
    }


    &:nth-child(4) {
      margin-right: 0;
    }
    
  }

  .contact_title,
  .contact_description {
    font-size: 24px;
    line-height: 28px;
  }

  .contacts_text {
    width: 80%;
    padding: 8.6vw 0 8.6vw 8.6vw;
    p {
      font-size: 40px;
      line-height: 47px;
      padding-bottom: 48px;
    }

    &:after {
      width: 96.5vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact {
    width: 30%;
    padding-bottom: 56px;

    &:not(:last-child) {
      margin-right: 0;
    }
  
    &:not(:nth-child(2)) {
      margin-right: 4.6vw;
    }

    &:nth-child(odd) {
      width: 50%;
    }
  }

  .contacts_text {
    width: 90%;

    p {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

@media screen and (max-width: 479px) {
  .contacts_wrap {
    padding-bottom: 80px;
    margin-bottom: 0;
  }

  .contact {
    width: 46%;
    padding-bottom: 19px;

    &:first-child {
      width: 100%;
      
      br {
        display: none;
      }
    }

    &:nth-child(odd) {
      width: auto;

    }

    &:not(:nth-child(3)) {
      margin-right: 0;
    }

    &:not(:nth-child(odd)) {
      margin-right: 32px;
      width: 44%;
    }
  }

  .contact_description, .contact_title {
    font-size: 16px;
    line-height: 19px;
  }

  .contacts_text {
    margin-top: 5px;
    padding: 12.5vw 0 12.5vw 10vw;

    p {
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 36px;
    }
  }
}