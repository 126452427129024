.about {
  display: flex;
  padding-bottom: 112px;
}

.about_text {
  margin-right: 12.8vw;
  width: 49%;
}

.about_text_info {
  padding: 64px 0 48px;
  border-bottom: 1px solid #7D7D7D;

  p {
    font-size: 1.25vw;
    line-height: 1.5vw;
    
    &:not(:last-child) {
      padding-bottom: 32px;
    }
  }
}

.about_wrap_img {
  width: 35%;
}

.about_img {
  max-width: 471px;
  width: 100%;
  max-height: 634px;
}

.about_number_wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}

.about_number {
  display: block;
  font-family: "Mazzard-Medium";
  font-size: 4.57vw;
  line-height: 5.43vw;
  font-weight: 500;

  &:nth-child(2) {
    &:before {
      content: 'с';
      font-size: 1.25vw;
      line-height: 1.5vw;
      font-weight: 400;
      margin-right: 8px;
    }
  }

  span {
    display: block;
    font-family: Mazzard;
    font-size: 1.25vw;
    line-height: 1.5vw;
    font-weight: 400;
  }
}

@media screen and (max-width: 1024px) {
  .about {
    padding-top: 32px;
  }

  .about_text {
    margin-right: 11.4vw;
  }

  .about_text_info {
    padding: 0 0 24px;

    p {
      font-size: 1.7vw;
      line-height: 2vw;

      &:not(:last-child) {
        padding-bottom: 24px;
      }
    }
  }

  .about_img {
    max-width: 281px;
  }

  .about_number {
    font-size: 4.7vw;
    line-height: 5.6vw;

    span, &:nth-child(2)::before {
      font-size: 1.7vw;
      line-height: 2vw;
    }

    &:nth-child(3) {
      width: 77px;
    }
  }

  .about_wrap_img {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .about_wrap {
    padding-top: 56px;
  }

  .about {
    padding-top: 24px;
    flex-direction: column;
  }

  .about_text {
    width: 100%;
    margin-right: 0;
  }

  .about_text_info {
    padding-bottom: 32px;

    p {
      font-size: 3.2vw;
      line-height: 3.7vw;


      &:not(:last-child) {
        padding-bottom: 36px;
      }
    }
  }

  .about_number {
    font-size: 9.4vw;
    line-height: 10.7vw;

    span, &:nth-child(2)::before {
      font-size: 3.2vw;
      line-height: 3.7vw;
    }

    &:nth-child(3) {
      width: auto;
    }
  }

  .about_wrap_img {
    width: 100%;
    padding-top: 64px;
  }

  .about_img {
    max-width: 100%;
    max-height: 924px;
  }
}

@media screen and (max-width: 479px) {
  .about_wrap {
    padding-top: 40px;
  }

  .about_text_info {
    padding-bottom: 16px;

    p {
      font-size: 16px;
      line-height: 19px;

      &:not(:last-child) {
        padding-bottom: 36px;
      }
    }
  }

  .about_number_wrap {
    padding-top: 16px;

  }

  .about_number {
    font-size: 40px;
    line-height: 47px;

    span, &:nth-child(2)::before {
      font-size: 14px;
      line-height: 17px;
    }
  
    &:nth-child(3) {
      width: 68px;
    }
  }

  .about_wrap_img {
    padding-top: 40px;
  }
}