$blockSize: 2em;
$delay: .065s;
$duration: .88s;
$startingColor: #2e2e2e;
$endingColor: #302d2d;

$startingColorMiddle: #ff7605;
$endingColorMiddle: #ff9b19;


$blockMargin: calc($blockSize / 5);
$loaderSize: calc($blockSize * 3 + $blockMargin * 2);

@mixin animation-delay($delay ) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin animation($ani1: null, $ani2: null) {
  @if ($ani2  !=null) {
    -webkit-animation: $ani1, $ani2;
    animation: $ani1, $ani2;
  }

  @else if ($ani1  !=null) {
    -webkit-animation: $ani1;
    animation: $ani1;
  }
}

.loader_wrap {
  background: #f0f0f0;
  display: block;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $loaderSize;
  height: $loaderSize;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loader_block {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  opacity: 0;
  width: $blockSize;
  height: $blockSize;
  background: $startingColor;
  @include animation(show $duration step-end infinite alternate,
    pulse $duration linear infinite alternate);
}

.loader_block:nth-child(1) {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  @include animation-delay(1 * $delay);
}

.loader_block:nth-child(2) {
  -webkit-transform: translate($blockSize + $blockMargin, 0);
          transform: translate($blockSize + $blockMargin, 0);
  @include animation-delay(2 * $delay);
}

.loader_block:nth-child(3) {
  -webkit-transform: translate(($blockSize * 2) + ($blockMargin * 2), 0);
          transform: translate(($blockSize * 2) + ($blockMargin * 2), 0);
  @include animation-delay(3 * $delay);
}

.loader_block:nth-child(4) {
  -webkit-transform: translate(0, $blockSize + $blockMargin);
          transform: translate(0, $blockSize + $blockMargin);
  @include animation(show $duration step-end infinite alternate,
    pulseTest $duration linear infinite alternate);
  @include animation-delay(5 * $delay);
}

.loader_block:nth-child(5) {
  -webkit-transform: translate($blockSize + $blockMargin, $blockSize + $blockMargin);
          transform: translate($blockSize + $blockMargin, $blockSize + $blockMargin);
  @include animation(show $duration step-end infinite alternate,
    pulseTest $duration linear infinite alternate);
  @include animation-delay(2 * $delay);
}

.loader_block:nth-child(6) {
  -webkit-transform: translate(($blockSize * 2) + ($blockMargin * 2), $blockSize + $blockMargin);
          transform: translate(($blockSize * 2) + ($blockMargin * 2), $blockSize + $blockMargin);
  @include animation(show $duration step-end infinite alternate,
    pulseTest $duration linear infinite alternate);
  @include animation-delay(7 * $delay);
}

.loader_block:nth-child(7) {
  -webkit-transform: translate(0, ($blockSize * 2) + ($blockMargin * 2));
          transform: translate(0, ($blockSize * 2) + ($blockMargin * 2));
  @include animation-delay(6 * $delay);
}

.loader_block:nth-child(8) {
  -webkit-transform: translate($blockSize + $blockMargin, ($blockSize * 2) + ($blockMargin * 2));
          transform: translate($blockSize + $blockMargin, ($blockSize * 2) + ($blockMargin * 2));
  @include animation-delay(4 * $delay);
}

.loader_block:nth-child(9) {
  -webkit-transform:  translate(($blockSize * 2) + ($blockMargin * 2), ($blockSize * 2) + ($blockMargin * 2));
          transform:  translate(($blockSize * 2) + ($blockMargin * 2), ($blockSize * 2) + ($blockMargin * 2));
}

@-webkit-keyframes pulse {

  from,
  40% {
    background: $startingColor;
  }

  to {
    background: $endingColor;
  }
}

@-webkit-keyframes show {

  from,
  40% {
    opacity: 0;
  }

  41%,
  to {
    opacity: 1;
  }
}

@keyframes pulse {

  from,
  40% {
    background: $startingColor;
  }

  to {
    background: $endingColor;
  }
}

@-webkit-keyframes pulseTest {

  from,
  40% {
    background: $startingColorMiddle;
  }

  to {
    background: $endingColorMiddle;
  }
}

@keyframes pulseTest {

  from,
  40% {
    background: $startingColorMiddle;
  }

  to {
    background: $endingColorMiddle;
  }
}

@keyframes show {

  from,
  40% {
    opacity: 0;
  }

  41%,
  to {
    opacity: 1;
  }
}