.policy_wrap {
  padding: 0 0 88px;
}

.policy {
  padding-top: 32px;
  
  p {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    width: 85%;
  }
}

.policy_title {
  display: block;
  font-size: 32px;
  line-height: 38px;
  color: #888888;
  margin: 32px 0 16px;
}

@media screen and (max-width: 1024px) {
  .policy_wrap {
    padding-top: 88px;
  }

  .policy {
    padding-top: 24px;

    p {
      font-size: 16px;
      line-height: 24px;
      width: 78%;
      margin-bottom: 16px;
    }
  }
  
  .policy_title {
    font-size: 24px;
    line-height: 28px;
    margin: 48px 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .policy_wrap {
    padding-top: 56px;

    h2 {
      font-size: 40px;
      line-height: 47px;
    }
  }

  .policy {
    padding-top: 16px;
    p {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .policy_title {
    margin: 32px 0 8px;
  }
}

@media screen and (max-width: 479px) {
  .policy_wrap {
    padding-top: 40px;

    h2 {
      font-size: 27px;
      line-height: 30px;
    }
  }
  
  .policy {

  }

  .policy_title {
    margin: 32px 0 8px;
    font-size: 16px;
    line-height: 19px;
  }
}