.header {
  padding-top: 16px;
    max-width: 93vw;
    margin: 0 auto;
}

label .menu {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 100;
  width: 24px;
  height: 24px;
  transition: .5s ease-in-out;
  box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
  cursor: pointer;
}

label .burger {
  width: 24px;
  height:1px;
  background: #000;
  display: block;
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: .5s ease-in-out;
}

label .burger:nth-child(1n),
label .burger:nth-child(3n) {
  transition: .5s ease-in-out;
  content: "";
  display: block;
  width: 24px;
  height: 1px;
  background: #000;
}

label .burger:nth-child(1n) {
  margin-bottom: 8px;
}

label .burger:nth-child(3n) {
  margin-top: 8px;
}

label input {
  display: none;
}

label input:checked+.menu {
  box-shadow: 0 0 0 100vw #FFF, 0 0 0 100vh #FFF;
  border-radius: 0;
  background: #fff;
}

label input:checked+.menu .burger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

}

label input:checked+.menu .burger:nth-child(1n) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: 0;
  margin: 0;
}

label input:checked+.menu .burger:nth-child(2n) {
  display: none;
}

label input:checked+.menu .burger:nth-child(3n) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 0;
  margin: 0;
}

label input + .menu+ul {
  li {
    display: none;
    transition: 2s;
  }
}

label input:checked+.menu+ul {
  opacity: 1;
  li {
    display: block;
    transition: 1s;
  }
}

label ul {
  width: 80vw;
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: .25s 0s ease-in-out;
}



.burger_Close {
  display: none;
}

.nav_link {
  display: none;
}

.burger_Open {
  display: block;
  
  .nav_link {
    display: block;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #2A2A2A;
  }
  
  li {
    list-style: none;

    &:not(:first-child) {
      padding-top: 24px;
    }

    &:not(:last-child) {
      transition: 0.5s;
      border-bottom: 1px solid #737373;
      padding-bottom: 24px;
    }
  }
}
